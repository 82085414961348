@use "sass:map";
@use "sass:math";

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Material Icons (All / Filled & Outline only)
// @import 'material-icons/iconfont/material-icons.scss';
// @import 'material-icons/iconfont/filled.scss';
// @import 'material-icons/iconfont/outlined.scss';

// Roboto font
// @import 'Roboto/roboto.css';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$STSL_Check-In_WebAdminClient-primary: mat.define-palette(mat.$indigo-palette);
$STSL_Check-In_WebAdminClient-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$STSL_Check-In_WebAdminClient-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$STSL_Check-In_WebAdminClient-theme: mat.define-light-theme(
    (
        color: (
            primary: $STSL_Check-In_WebAdminClient-primary,
            accent: $STSL_Check-In_WebAdminClient-accent,
            warn: $STSL_Check-In_WebAdminClient-warn,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($STSL_Check-In_WebAdminClient-theme);

/* You can add global styles to this file, and also import other style files */

$breakpoints: (
    "sm": 480px,
    "md": 800px,
);

html,
body {
    --colour-primary: map.get($STSL_Check-In_WebAdminClient-primary, 500);
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

img {
    vertical-align: middle;
}

// Utility classes
.sticky {
    position: sticky;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.fixed {
    position: fixed;
}

.inline-block {
    display: inline-block;
}

.inset-0 {
    inset: 0;
}

.top-0 {
    top: 0;
}

.left-0 {
    left: 0;
}

.bottom-0 {
    bottom: 0;
}

.top-center {
    top: 50%;
    transform: translateY(-50%);
}

.left-center {
    left: 50%;
    transform: translateX(-50%);
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.text-center {
    text-align: center;
}

.text-white {
    color: white;
}

.grid {
    display: grid;
}

.grid-responsive {
    display: grid;

    @media screen and (max-width: map.get($breakpoints, "md")) {
        grid-template-columns: unset !important;
    }
}

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.flex-col {
    flex-direction: column;
}

.flex-responsive {
    display: flex;
    flex-direction: column;

    // opinionated: flex children should grow by default to fill gaps
    > * {
        flex-grow: 1;
    }

    @media screen and (min-width: map.get($breakpoints, "md")) {
        flex-direction: row;
        // opinionated: flex children should wrap if they are too wide
        flex-wrap: wrap;

        > * {
            flex-grow: unset;
        }
    }
}

.flex-grow {
    flex-grow: 1;
}

.flex-wrap {
    flex-wrap: wrap;
}

.items-center {
    align-items: center;
}

.items-stretch {
    align-items: stretch;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.bg-primary {
    background-color: map.get($STSL_Check-In_WebAdminClient-primary, 500);
}

.bg-white {
    background-color: white;
}

.aspect-square {
    aspect-ratio: 1;
}

.border-horizontal {
    border-block-style: solid;
    border-block-width: 1px;
}

.rounded {
    border-radius: 4px;
}

.img-thumbnail {
    max-width: 150px;
    // max-height: 150px;
    border-radius: 4px;
}

.rounded-circle {
    border-radius: 100vmax;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-icon {
    max-height: 2rem;
    aspect-ratio: 1;
}

.z-100 {
    z-index: 100;
}

.cursor-pointer {
    cursor: pointer;
}

.reset-all {
    all: unset;
}

.overflow-y-auto {
    overflow-y: auto;
}

// Size of step for variable size utility classes
$gap-iteration-size: 0.25rem;
$generated-iterative-size-utility-class-count: 8;

// Note: We have to use !important to override some Material properties that
// Material doesn't provide any options for modifying.

// Generate classes for variable sizes i.e. padding, margin, gap etc
@for $iterator from 0 through $generated-iterative-size-utility-class-count {
    .p-#{$iterator} {
        padding: $iterator * $gap-iteration-size !important;
    }

    .px-#{$iterator} {
        padding-inline: $iterator * $gap-iteration-size !important;
    }

    .py-#{$iterator} {
        padding-block: $iterator * $gap-iteration-size !important;
    }

    .mx-#{$iterator} {
        margin-inline: $iterator * $gap-iteration-size !important;
    }

    .my-#{$iterator} {
        margin-block: $iterator * $gap-iteration-size !important;
    }

    .gap-#{$iterator} {
        gap: $iterator * $gap-iteration-size !important;
        // Sets the CSS variable which is inherited by children and allows
        // them to use the gap size to calculate their width - gap
        --gap: #{$iterator * $gap-iteration-size};
    }
}

$max-generated-column-classes: 7;
// Generate column classes for flex containers
@for $iterator from 2 through $max-generated-column-classes {
    // This accounts for the fact that gap is between items only
    // i.e. if you have a gap of 2rem and 2 items, the actual width is 50% - ((2 - 1) / 2) == 0.5 * 2rem == (1)rem
    // If we encounter rounding errors leading to wrapping elements where they should be exact fit; we can do $iterator - 1.00000001
    .cols-#{$iterator} {
        > * {
            width: calc((100% / $iterator) - calc((var(--gap) * math.div(($iterator - 1), $iterator))));
        }
    }

    .grid-cols-#{$iterator} {
        grid-template-columns: repeat($iterator, 1fr);
    }

    @media screen and (max-width: map.get($breakpoints, "md")) {
        .flex-responsive.cols-#{$iterator} {
            > * {
                width: unset;
            }
        }
    }
}

.cols-auto {
    > * {
        flex-grow: 1;
    }
}
