@font-face {
    font-family: 'Roboto';
    src: url('roboto-regular-webfont.woff2') format('woff2'),
         url('roboto-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('roboto-italic-webfont.woff2') format('woff2'),
         url('roboto-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('roboto-light-webfont.woff2') format('woff2'),
    url('roboto-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('roboto-lightitalic-webfont.woff2') format('woff2'),
         url('roboto-lightitalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('roboto-medium-webfont.woff2') format('woff2'),
         url('roboto-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('roboto-mediumitalic-webfont.woff2') format('woff2'),
         url('roboto-mediumitalic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
